<template>
  <div class="pdf-history-listing" v-if="getPermission('history:view')">
    <v-container fluid class="custom-bthrust-style">
      <v-container>
        <v-layout>
          <template>
            <v-row no-gutters>
              <v-col md="2">
                <div
                  :class="'card m-0 top_card card-custom gutter-b border-primary'"
                >
                  <div class="card-body p-0">
                    <div
                      :class="'card-rounded-bottom mid_part shadow-sm bg-light-black bg-front-white'"
                    >
                      <div
                        class="d-flex align-items-center justify-content-between card-spacer pb-2 flex-grow-1"
                      >
                        <span
                          class="symbol symbol-circle symbol-50 symbol-light-danger mr-2"
                        >
                          <span class="symbol-label">
                            <span
                              :class="'svg-icon svg-icon-xl svg-icon-black'"
                            >
                              <inline-svg
                                :src="
                                  $assetURL('media/custom-svg/quotation.svg')
                                "
                              />
                            </span>
                          </span>
                        </span>
                        <div class="w-100 d-flex flex-column text-right bTitle">
                          <h5 class="text-dark-75 font-weight-bold mt-2">
                            <b>Expired</b>
                          </h5>
                          <template>
                            <span
                              :class="'qnt_val font-weight-bolder text-primary'"
                              >{{ expired_count }} / {{ total_rows }}</span
                            >
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </template>
          <template v-if="false">
            <div class="mr-2 my-2">
              <v-text-field
                v-model.trim="filter.search"
                v-on:click:clear="clearFilter('search')"
                clearable
                dense
                filled
                flat
                label="Search"
                hide-details
                solo
                :disabled="pageLoading"
                color="cyan"
                @keydown.enter="getHistories"
                @keydown.tab="getHistories"
              ></v-text-field>
            </div>
            <div class="mx-2 my-2">
              <v-menu
                v-model="datePicker"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    filled
                    flat
                    label="Date Range"
                    hide-details
                    solo
                    :disabled="pageLoading"
                    v-on:click:clear="clearFilter('dates')"
                    clearable
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :value="formattedDate"
                    color="cyan"
                  ></v-text-field>
                </template>
                <v-date-picker
                  color="cyan"
                  range
                  :disabled="pageLoading"
                  v-on:change="getHistories"
                  v-model="dates"
                ></v-date-picker>
              </v-menu>
            </div>
            <div class="ml-2 my-2">
              <v-tooltip top content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="custom-bold-button white--text"
                    color="cyan"
                    tile
                    :disabled="pageLoading"
                    v-on:click="getHistories"
                  >
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </template>
                <span>Search</span>
              </v-tooltip>
            </div></template
          >
        </v-layout>
      </v-container>

      <v-simple-table fixed-header height="67vh">
        <template v-slot:default>
          <thead>
            <tr>
              <th width="300" class="simple-table-th">Description</th>
              <th width="300" class="simple-table-th">Remarks</th>
              <th width="300" class="simple-table-th">IP Address</th>
              <th width="300" class="simple-table-th">Quote Date</th>
              <th width="300" class="simple-table-th">Valid Till</th>
              <th width="300" class="simple-table-th">Created Time</th>
              <th width="300" class="simple-table-th">Download</th>
              <th width="300" class="simple-table-th">Converted</th>
              <th width="300" class="simple-table-th">Attachments</th>
              <th width="300" class="simple-table-th"></th>
            </tr>
          </thead>
          <tbody>
            <template v-if="histories.length > 0">
              <tr v-for="(history, index) in histories" :key="index">
                <td
                  class="simple-table-td"
                  style="padding-left: 15px !important"
                >
                  {{ history.description }}
                  <span class="text--secondary">
                    <template v-if="history.added_by">
                      Downloaded by
                      {{ history.added_by.display_name }}</template
                    >
                  </span>
                </td>
                <td
                  class="simple-table-td"
                  width="200"
                  style="padding-left: 15px !important"
                >
                  {{ history.checked_remark_pdf }}
                </td>
                <td
                  class="simple-table-td"
                  width="200"
                  style="padding-left: 15px !important"
                >
                  {{ history.ip_address }}
                </td>
                <td
                  class="simple-table-td"
                  width="200"
                  style="padding-left: 15px !important"
                >
                  {{ formatDate(history.download_date) }}
                </td>
                <td
                  class="simple-table-td"
                  width="200"
                  style="padding-left: 15px !important"
                >
                  <template>
                    <v-chip
                      v-bind="attrs"
                      v-on="on"
                      v-if="history.status > 0"
                      class="d-inline mx-2 custom-status font-weight-600 custom-grey-border d-inline-table text-uppercase justify-center font-10"
                      color="black"
                      text-color="white"
                      label
                      small="small"
                    >
                      Expired
                    </v-chip>
                  </template>
                  {{ formatDate(history.valid_till) }}
                </td>
                <td
                  class="simple-table-td"
                  width="300"
                  style="padding-left: 15px !important"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        v-if="history.created_at"
                        class="my-1 custom-grey-border"
                        color="blue-grey darken-1"
                        text-color="white"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-avatar left>
                          <v-icon>mdi-clock-outline</v-icon>
                        </v-avatar>
                        <p class="m-0 custom-nowrap-ellipsis">
                          {{ history.created_at }}
                        </p>
                      </v-chip>
                    </template>
                    <p class="m-0 custom-nowrap-ellipsis">
                      {{ formatDate(history.added_at) }}
                      {{ formatDateStartTime(history.added_at) }}
                    </p>
                  </v-tooltip>
                </td>
                <td
                  width="150"
                  class="font-size-16 font-weight-500"
                  align="left"
                  style="padding-left: 15px !important"
                >
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        :disabled="!history.pdf_url"
                        color="cyan"
                        v-on:click.stop.prevent="downloadFile(history.pdf_url)"
                        icon
                      >
                        <v-icon>mdi-file-pdf-box</v-icon>
                      </v-btn>
                    </template>
                    <span>Download PDF{{ history.id }}</span>
                  </v-tooltip>
                </td>
                <td
                  class="simple-table-td"
                  width="200"
                  style="padding-left: 15px !important"
                >
                  <p>
                    <v-checkbox
                      color="cyan"
                      hide-details
                      v-model="history.checked"
                      :disabled="
                        history.quotation.status == 2 ||
                        Boolean(history.checked)
                      "
                      v-on:click="closeddata(history)"
                    ></v-checkbox>
                  </p>
                  <p
                    v-if="history.quotation && history.quotation.approved_date"
                  >
                    {{ formatDate(history.quotation.approved_date) }}
                  </p>
                </td>
                <td
                  class="simple-table-td"
                  width="200"
                  style="padding-left: 15px !important"
                >
                  <v-layout
                    v-if="history.documents.length"
                    class="custom-border-top"
                  >
                    <v-flex md12 class="">
                      <FileTemplateName
                        :attachments="history.documents"
                        :allowUpload="false"
                        :allowDelete="false"
                        :isMinDisplay="6"
                      ></FileTemplateName>
                    </v-flex>
                  </v-layout>
                </td>
                <td
                  class="simple-table-td"
                  width="200"
                  style="padding-left: 15px !important"
                >
                  <p>
                    <v-btn
                      :disabled="
                        history.quotation.status == 2 || history.is_sent
                      "
                      class="mx-2 custom-bold-button white--text"
                      color="cyan"
                      v-bind="attrs"
                      v-on="on"
                      v-on:click="sendMail(history)"
                    >
                      <v-icon left>mdi-send mdi-rotate-315</v-icon> Send Mail
                    </v-btn>
                  </p>

                  <v-tooltip bottom v-if="history.is_sent && history.send_date">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        v-if="history.created_at"
                        class="my-1 custom-grey-border"
                        color="blue-grey darken-1"
                        text-color="white"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-avatar left>
                          <v-icon>mdi-clock-outline</v-icon>
                        </v-avatar>
                        <p class="m-0 custom-nowrap-ellipsis">
                          {{ history.send_at }}
                        </p>
                      </v-chip>
                    </template>
                    <p class="m-0 custom-nowrap-ellipsis">
                      {{ formatDateTime(history.send_date) }}
                    </p>
                  </v-tooltip>
                </td>
              </tr>
            </template>
            <template v-else>
              <template v-if="pageLoading">
                <tr v-for="row in 5" :key="row">
                  <td>
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton table-rows-text"
                    ></v-skeleton-loader>
                  </td>
                  <td>
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton table-rows-text"
                    ></v-skeleton-loader>
                  </td>
                  <td>
                    <v-skeleton-loader
                      type="text"
                      class="custom-skeleton table-rows-text"
                    ></v-skeleton-loader>
                  </td>
                </tr>
              </template>
              <tr v-else-if="!pageLoading">
                <td colspan="3">
                  <p
                    class="m-0 row-not-found text-center font-weight-500 font-size-16"
                  >
                    <img
                      :src="$assetURL('media/error/empty.png')"
                      class="row-not-found-image"
                    />
                    Uhh... There are no history at the moment.
                  </p>
                </td>
              </tr>
            </template>
          </tbody>
        </template>
      </v-simple-table>
      <ListingFooter
        :dataLoading="pageLoading"
        :currentPage="current_page"
        :totalPages="total_pages"
        :showingFrom="showingFrom"
        :showingTo="showingTo"
        :totalRows="total_rows"
      ></ListingFooter>
      <ClosedRemark
        :dialog="manageClosedRemark"
        :data_histories="historydata"
        v-on:get-validityoptions="getHistories"
        v-on:close-dialog="manageClosedRemark = false"
        v-on:success="success = true"
      ></ClosedRemark>
      <SendMail
        :mail-dialog="mail_dialog"
        title="Send as Email"
        type="quotation"
        v-on:close="mail_dialog = false"
        :type-id="quotationId"
        :download-id="downloadId"
        v-on:success="mailSuccess = true"
      ></SendMail>
    </v-container>
  </div>
</template>

<script>
import { QUERY } from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingFooter from "@/view/pages/partials/Listing-Footer";
import { PaginationEventBus } from "@/core/lib/pagination.lib";
import ClosedRemark from "@/view/pages/partials/Detail/Closed-Remark.vue";
import SendMail from "@/view/pages/partials/Send-Mail";
import FileTemplateName from "@/view/pages/partials/FileTemplateName.vue";
import moment from "moment";

export default {
  mixins: [CommonMixin],
  data() {
    return {
      quotationId: null,
      downloadId: null,
      mail_dialog: false,
      historydata: null,
      dates: [],
      manageClosedRemark: false,
      pageLoading: false,
      histories: new Array(),
      filter: {
        search: null,
      },
      per_page: 50,
      current_page: 1,
      offset: 0,
      total_pages: 0,
      total_rows: 0,
      expired_count: 0,
      success: false,
      mailSuccess: false,
    };
  },
  components: {
    ListingFooter,
    ClosedRemark,
    SendMail,
    FileTemplateName,
  },
  props: {
    type: {
      required: true,
      type: String,
      default: "",
    },
    type_id: {
      required: true,
      type: Number,
      default: 0,
    },
  },
  watch: {
    type_id() {
      let type_id = this.lodash.toSafeInteger(this.type_id);
      console.log(type_id);
      if (type_id > 0) {
        this.getHistories();
      }
    },
    success() {
      if (this.success) {
        this.$emit("success", true);
      }
      this.success = false;
    },
    mailSuccess() {
      if (this.mailSuccess) {
        this.$emit("success", true);
        this.getHistories();
      }
      this.mailSuccess = false;
    },
  },
  methods: {
    formatDateStartTime(row) {
      return moment(row).format("hh:mm A");
    },
    sendMail(data) {
      this.mail_dialog = true;
      this.quotationId = data.parent;
      this.downloadId = data.id;
    },
    closeddata(data) {
      this.manageClosedRemark = true;
      this.historydata = data;
    },
    downloadFile(url, prepend_url) {
      if (!url) {
        return false;
      }
      if (prepend_url) {
        url = `https://bthrust-fsm.s3.ap-southeast-1.amazonaws.com/${url}`;
      }
      window.open(url, "_blank");
    },
    clearFilter(field) {
      const _this = this;
      switch (field) {
        case "search":
          _this.filter.search = null;
          break;
        case "dates":
          _this.dates = [];
          break;
      }
      _this.getHistories();
    },
    getHistories() {
      const _this = this;
      _this.pageLoading = true;
      _this.histories = [];
      _this.$store
        .dispatch(QUERY, {
          url: "quotation/pdf/history",
          data: {
            type: _this.type,
            type_id: _this.type_id,
            per_page: _this.per_page,
            current_page: _this.current_page,
            search: _this.filter.search,
            start_date: _this.dates[0] || null,
            end_date: _this.dates[1] || null,
          },
        })
        .then(({ data }) => {
          _this.offset = data.offset;
          _this.histories = data.rows;
          _this.total_pages = Math.ceil(data.total_rows / data.per_page);
          _this.current_page = data.current_page;
          _this.total_rows = data.total_rows;
          _this.expired_count = data.expired_count;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  beforeDestroy() {
    PaginationEventBus.$off("update:pagination");
  },
  mounted() {
    const _this = this;
    let type_id = _this.lodash.toSafeInteger(_this.type_id);
    if (type_id > 0) {
      _this.getHistories();
    }

    PaginationEventBus.$on("update:pagination", (param) => {
      _this.current_page = param;
      _this.getHistories();
    });

    PaginationEventBus.$on("histories-on-refresh", function () {
      _this.getHistories();
    });

    PaginationEventBus.$on("histories-download-refresh", function () {
      _this.getHistories();
    });
  },
  computed: {
    showingFrom() {
      return this.lodash.isEmpty(this.histories) === false
        ? this.offset + 1
        : 0;
    },
    showingTo() {
      return this.offset + (this.histories ? this.histories.length : 0);
    },
  },
};
</script>
