<template>
  <DetailTemplate
    :custom-class="'quotation-detail detail-page'"
    v-if="getPermission('quotation:view')"
  >
    <template v-slot:header-title>
      <div class="d-flex align-center" v-if="pageLoaded">
        <span class="color-custom-blue font-weight-700 font-size-20">{{
          detail.job_title
        }}</span>
        <CustomStatus
          :status="detail.status"
          endpoint="quotation/status"
        ></CustomStatus>
        <template v-if="detail.is_revised">
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-bind="attrs"
                v-on="on"
                color="red lighten-4"
                class="ml-0 mr-2 black--text font-weight-600"
                label
              >
                REVISED
              </v-chip>
            </template>
            <span>Quotation is Revised</span>
          </v-tooltip>
        </template>
        <template v-if="detail.is_contract">
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-bind="attrs"
                v-on="on"
                color="green lighten-1"
                class="ml-0 mr-2 white--text font-weight-600"
                label
              >
                CONTRACT
              </v-chip>
            </template>
            <span>Contract</span>
          </v-tooltip>
        </template>
        <template v-if="detail.revise">
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-bind="attrs"
                v-on="on"
                color="teal lighten-4"
                class="ml-0 mr-2 black--text font-weight-600"
                label
                link
              >
                REVISION
              </v-chip>
            </template>
            <span>Revision of {{ detail.revise.barcode }}</span>
          </v-tooltip>
        </template>
      </div>
    </template>
    <template v-slot:header-action>
      <div class="d-flex align-center justify-end" v-if="pageLoaded">
        <template v-if="false && detail.status == 1">
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="statusLoading"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                v-bind="attrs"
                v-on="on"
                v-on:click="updateMoreAction('mark_as_approved')"
              >
                <v-icon left>mdi-check</v-icon> Approve
              </v-btn>
            </template>
            <span>Click here to Approve</span>
          </v-tooltip>
        </template>
        <template v-if="false && detail.status == 1 && userDetails.role == 3">
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="statusLoading"
                class="mx-2 custom-bold-button white--text"
                color="red"
                v-bind="attrs"
                v-on="on"
                v-on:click="updateMoreAction('mark_as_rejected')"
              >
                <v-icon left>mdi-close </v-icon> Reject
              </v-btn>
            </template>
            <span>Click here to Reject</span>
          </v-tooltip>
        </template>
        <template v-if="detail.status == 3 && !detail.is_revised">
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="statusLoading"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                v-bind="attrs"
                v-on="on"
                v-on:click="updateMoreAction('revise')"
              >
                <v-icon left>mdi-update</v-icon> Revise
              </v-btn>
            </template>
            <span>Click here to revise Quote</span>
          </v-tooltip>
        </template>
        <template
          v-if="
            detail.status != 7 &&
            detail.status != 6 &&
            detail.status != 3 &&
            detail.status != 2 &&
            detail.status != 5 &&
            detail.status != 8
          "
        >
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="statusLoading"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                v-bind="attrs"
                v-on="on"
                v-on:click="updateMoreAction('edit')"
              >
                <v-icon left>mdi-pencil</v-icon> Edit
              </v-btn>
            </template>
            <span>Click here to edit Quote</span>
          </v-tooltip>
        </template>
        <v-menu
          transition="slide-y-transition"
          bottom
          content-class="custom-menu-list"
          offset-y
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="statusLoading"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              v-bind="attrs"
              v-on="on"
            >
              More... <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template v-for="(more, index) in moreActions">
              <v-list-item
                link
                v-on:click="updateMoreAction(more.action)"
                :key="index"
                :disabled="statusLoading || more.disabled"
              >
                <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                  <v-icon class="icon-default-blue">{{ more.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="font-weight-500 font-size-14">{{
                  more.title
                }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
        <v-btn
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="goBack()"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </div>
    </template>
    <template v-slot:body>
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll quotation-detail-height"
        style="position: relative"
      >
        <v-container fluid>
          <v-row v-if="pageLoaded">
            <v-col md="5">
              <span class="color-custom-blue font-weight-700 font-size-19">
                <router-link
                  :to="
                    getDefaultRoute('customer.detail', {
                      params: {
                        id: detail.customer && detail.customer.id,
                      },
                    })
                  "
                  >{{ detail.customer && detail.customer.display_name }}
                </router-link>
              </span>
              <v-layout class="my-4">
                <v-flex md6 class="mr-2 custom-border-right">
                  <table width="100%">
                    <tr>
                      <td class="font-weight-600">Service Location</td>
                    </tr>
                    <tr>
                      <td class="py-0 font-size-18 font-weight-500">
                        <a
                          target="_blank"
                          :href="`https://maps.google.com/?q=${detail.property.property_address}`"
                          >{{ detail.property.property_address }}</a
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-600">Contact details</td>
                    </tr>
                    <tr
                      v-if="
                        detail.property_person &&
                        detail.property_person.display_name
                      "
                    >
                      <td class="py-0 font-size-18 font-weight-500">
                        {{ detail.property_person.display_name }}
                      </td>
                    </tr>
                    <tr
                      v-if="
                        detail.property_person &&
                        detail.property_person.primary_phone
                      "
                    >
                      <td class="py-0 font-size-18 font-weight-500">
                        {{ detail.property_person.primary_phone }}
                      </td>
                    </tr>
                    <tr
                      v-if="
                        detail.property_person &&
                        detail.property_person.primary_email
                      "
                    >
                      <td class="py-0 font-size-18 font-weight-500">
                        {{ detail.property_person.primary_email }}
                        <v-icon
                          v-if="detail.property_person_notify"
                          v-tippy="{
                            arrow: true,
                            arrowType: 'round',
                            animation: 'fade',
                          }"
                          content="Notification was sent to given email"
                          color="green lighten-1"
                          >mdi-check</v-icon
                        >
                      </td>
                    </tr>
                    <tr>
                      <td
                        valign="middle"
                        class="py-1 font-size-18 font-weight-600"
                      >
                        Delivery Site (Service Locaiton As Default)
                      </td>
                    </tr>
                    <tr>
                      <td
                        valign="middle"
                        class="py-1 font-size-18 font-weight-500"
                      >
                        <label class="m-0">{{ detail.delivery_site }}</label>
                      </td>
                    </tr>
                  </table>
                </v-flex>
                <v-flex md6 class="ml-2">
                  <table width="100%">
                    <tr>
                      <td class="font-weight-600">Billing Address</td>
                    </tr>
                    <tr>
                      <td class="py-0 font-size-18 font-weight-500">
                        <a
                          target="_blank"
                          :href="`https://maps.google.com/?q=${detail.billing.property_address}`"
                          >{{ detail.billing.property_address }}</a
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-600">Contact details</td>
                    </tr>
                    <tr
                      v-if="
                        detail.billing_person &&
                        detail.billing_person.display_name
                      "
                    >
                      <td class="py-0 font-size-18 font-weight-500">
                        {{ detail.billing_person.display_name }}
                      </td>
                    </tr>
                    <tr
                      v-if="
                        detail.billing_person &&
                        detail.billing_person.primary_phone
                      "
                    >
                      <td class="py-0 font-size-18 font-weight-500">
                        {{ detail.billing_person.primary_phone }}
                      </td>
                    </tr>
                    <tr
                      v-if="
                        detail.billing_person &&
                        detail.billing_person.primary_email
                      "
                    >
                      <td class="py-0 font-size-18 font-weight-500">
                        {{ detail.billing_person.primary_email }}
                        <v-icon
                          v-if="detail.billing_person_notify"
                          v-tippy="{
                            arrow: true,
                            arrowType: 'round',
                            animation: 'fade',
                          }"
                          content="Notification was sent to given email"
                          color="green lighten-1"
                          >mdi-check</v-icon
                        >
                      </td>
                    </tr>
                  </table>
                </v-flex>
              </v-layout>
            </v-col>
            <v-col md="7" class="custom-border-left gray-background">
              <table width="100%">
                <tr>
                  <td
                    valign="middle"
                    class="py-1 font-size-18 font-weight-500"
                    colspan="2"
                  >
                    <span
                      class="color-custom-blue font-weight-700 font-size-19"
                      >{{ detail.job_title }}</span
                    >
                  </td>
                </tr>
                <tr>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Quotation #
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0">{{ detail.barcode }}</label>
                  </td>
                </tr>
                <tr>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Rate opportunity
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <v-rating
                      readonly
                      dense
                      v-model.trim="detail.rating"
                      background-color="orange lighten-3"
                      color="orange"
                    ></v-rating>
                  </td>
                </tr>
                <tr>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    C/O or Customer Ref/Your Ref on PDF<!-- Customer Reference # -->
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0">{{ detail.reference }}</label>
                  </td>
                </tr>
                <tr>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Internal Reference #
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0">{{ detail.your_reference }}</label>
                  </td>
                </tr>
                <tr v-if="detail.sales_user">
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Sales representatives
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0"
                      ><router-link
                        :to="{
                          name: 'admin.user.detail',
                          params: { id: detail.sales_user.id },
                        }"
                        >{{ detail.sales_user.display_name }}</router-link
                      ></label
                    >
                  </td>
                </tr>
                <tr v-if="detail.prepared_user">
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Prepared by
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0"
                      ><router-link
                        :to="{
                          name: 'admin.user.detail',
                          params: { id: detail.prepared_user.id },
                        }"
                        >{{ detail.prepared_user.display_name }}</router-link
                      ></label
                    >
                  </td>
                </tr>

                <tr v-if="detail.fax">
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    FAX
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0">{{ detail.fax }}</label>
                  </td>
                </tr>
                <tr v-if="detail.did">
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    DID
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0">{{ detail.did }}</label>
                  </td>
                </tr>
                <tr v-if="false && detail.approved_user">
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Approved by
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0"
                      ><router-link
                        :to="{
                          name: 'admin.user.detail',
                          params: { id: detail.approved_user.id },
                        }"
                        >{{ detail.approved_user.display_name }}</router-link
                      ></label
                    >
                  </td>
                </tr>

                <tr v-if="detail.approved_reject_remarks">
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Rejected Remark
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0">{{
                      detail.approved_reject_remarks
                    }}</label>
                  </td>
                </tr>
                <tr v-if="detail.accepted_remarks">
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Accepted Remark
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0">{{ detail.accepted_remarks }}</label>
                  </td>
                </tr>
                <tr v-if="detail.approved_date">
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Accepted Date
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0">{{ detail.approved_date }}</label>
                  </td>
                </tr>
                <tr v-if="detail.is_contract">
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Is Contract ?
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0">{{
                      detail.is_contract ? "Yes" : "No"
                    }}</label>
                  </td>
                </tr>
                <tr v-if="detail.is_contract">
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Contract Duration
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0"
                      >{{ formatDate(detail.contract_start) }} to
                      {{ formatDate(detail.contract_end) }} ({{
                        detail.duration_value
                      }}
                      {{ detail.duration_type }})</label
                    >
                  </td>
                </tr>
                <tr v-if="detail.is_contract">
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    No. of Services
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0">{{ detail.total_service }}</label>
                  </td>
                </tr>
              </table>
              <!-- <hr /> -->
              <table width="100%" v-if="false">
                <tr>
                  <td
                    width="25%"
                    valign="middle"
                    class="py-1 font-size-18 font-weight-600"
                  >
                    Quotation Date
                  </td>
                  <td
                    width="25%"
                    valign="middle"
                    class="custom-border-left px-4 py-1 font-size-18 font-weight-600"
                  >
                    Quotation Open Till
                  </td>
                  <td
                    width="25%"
                    valign="middle"
                    class="custom-border-left px-4 py-1 font-size-18 font-weight-600"
                  >
                    Quotation Accepted Date
                  </td>
                  <td
                    width="25%"
                    valign="middle"
                    class="custom-border-left px-4 py-1 font-size-18 font-weight-600"
                  >
                    Quotation Sent Date
                  </td>
                </tr>
                <tr>
                  <td
                    width="25%"
                    valign="middle"
                    class="py-1 font-size-18 font-weight-500"
                  >
                    {{ formatDate(detail.date) }}
                  </td>
                  <td
                    width="25%"
                    valign="middle"
                    class="custom-border-left px-4 py-1 font-size-18 font-weight-500"
                  >
                    {{ formatDate(detail.open_till) }}
                  </td>
                  <td
                    width="25%"
                    valign="middle"
                    class="custom-border-left px-4 py-1 font-size-18 font-weight-500"
                  >
                    {{ formatDate(detail.accepted_date) }}
                  </td>
                  <td
                    width="25%"
                    valign="middle"
                    class="custom-border-left px-4 py-1 font-size-18 font-weight-500"
                  >
                    {{ formatDate(detail.sent_date) }}
                  </td>
                </tr>
              </table>
            </v-col>
            <v-col md="12">
              <v-tabs
                active-class="custom-tab-active"
                v-model="quotationTab"
                background-color="transparent"
                color="cyan"
                class="custom-tab-transparent tab-sticky"
              >
                <v-tab class="font-size-16 font-weight-600 px-8" href="#other">
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/quotation.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  Overview
                </v-tab>
                <v-tab
                  class="font-size-16 font-weight-600 px-8"
                  href="#line-item"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/line-item.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  Line Items
                </v-tab>
                <v-tab
                  v-if="false"
                  class="font-size-16 font-weight-600 px-8"
                  href="#invoice"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/invoice.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  Invoices
                </v-tab>
                <v-tab class="font-size-16 font-weight-600 px-8" href="#visit">
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/visit.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  Visits
                </v-tab>
                <v-tab
                  class="font-size-16 font-weight-600 px-8"
                  href="#history"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/history.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  History
                </v-tab>
                <v-tab
                  class="font-size-16 font-weight-600 px-8"
                  href="#pdfHistory"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/history.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  PDF History
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="quotationTab">
                <v-tab-item value="other">
                  <v-container fluid>
                    <v-card
                      flat
                      class="custom-grey-border remove-border-radius mb-4"
                    >
                      <v-card-title class="headline grey lighten-4">
                        <span
                          class="font-weight-700 custom-headline color-custom-blue font-size-16"
                          >Description</span
                        >
                      </v-card-title>
                      <v-card-text class="p-6 font-size-16">
                        <read-more
                          class="mx-4 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                          more-str="read more"
                          :text="
                            detail.description || '<em>No Description</em>'
                          "
                          link="#"
                          less-str="read less"
                          :max-chars="200"
                        >
                        </read-more>
                      </v-card-text>
                    </v-card>
                    <v-card
                      flat
                      class="custom-grey-border remove-border-radius mb-4"
                    >
                      <v-card-title class="headline grey lighten-4">
                        <span
                          class="font-weight-700 custom-headline color-custom-blue font-size-16"
                          >REMARKS FOR PDF</span
                        >
                      </v-card-title>
                      <v-card-text class="p-6 font-size-16">
                        <v-layout class="mb-4">
                          <v-flex md6 class="custom-border-right">
                            <read-more
                              class="mx-4 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                              more-str="read more"
                              :text="
                                detail.client_remark || '<em>No Remarks</em>'
                              "
                              link="#"
                              less-str="read less"
                              :max-chars="200"
                            >
                            </read-more>
                          </v-flex>
                          <!--  <v-flex md6>
                            <read-more
                              class="mx-4 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                              more-str="read more"
                              :text="
                                detail.admin_remark || '<em>No Admin Notes</em>'
                              "
                              link="#"
                              less-str="read less"
                              :max-chars="200"
                            >
                            </read-more>
                          </v-flex> -->
                        </v-layout>
                        <v-layout
                          v-if="false && detail.documents.length"
                          class="custom-border-top"
                        >
                          <v-flex md12 class="">
                            <FileTemplateName
                              :attachments="detail.documents"
                              :allowUpload="false"
                            ></FileTemplateName>
                          </v-flex>
                        </v-layout>
                      </v-card-text>
                    </v-card>
                    <v-card
                      flat
                      class="custom-grey-border remove-border-radius mb-4"
                    >
                      <v-card-title class="headline grey lighten-4">
                        <span
                          class="font-weight-700 custom-headline color-custom-blue font-size-16"
                          >TERMS &amp; CONDITIONS</span
                        >
                      </v-card-title>
                      <!-- <v-card-text class="p-6 font-size-16">
                        <read-more
                          class="
                            mx-4
                            custom-read-more
                            font-weight-600 font-size-18
                            pb-1
                            custom-gray-color
                          "
                          more-str="read more"
                          :text="
                            detail.term_conditions ||
                            '<em>No Terms &amp; Conditions</em>'
                          "
                          link="#"
                          less-str="read less"
                          :max-chars="200"
                        >
                        </read-more>
                      </v-card-text> -->
                      <v-card-text class="p-6 font-size-16">
                        <div class="d-flex">
                          <p class="m-0 p-2 w-50 custom-grey-border">
                            <template v-if="detail.validity">
                              {{ detail.validity }}
                            </template>
                            <template v-else>
                              <em>No Validity Terms &amp; Conditions</em>
                            </template>
                          </p>
                          <p class="m-0 p-2 w-50 custom-grey-border">
                            <template v-if="detail.payment">
                              {{ detail.payment }}
                            </template>
                            <template v-else>
                              <em>No Payment Terms &amp; Conditions</em>
                            </template>
                          </p>
                        </div>
                        <div class="d-flex">
                          <p class="m-0 p-2 w-50 custom-grey-border">
                            <template v-if="detail.delivery">
                              {{ detail.delivery }}
                            </template>
                            <template v-else>
                              <em>No Delivery Terms &amp; Conditions</em>
                            </template>
                          </p>
                          <p class="m-0 p-2 w-50 custom-grey-border">
                            <template v-if="detail.deposit">
                              {{ detail.deposit }}
                            </template>
                            <template v-else>
                              <em>No Deposit Terms &amp; Conditions</em>
                            </template>
                          </p>
                        </div>
                        <div class="d-flex">
                          <p class="m-0 p-2 w-50 custom-grey-border">
                            <template v-if="detail.warranty">
                              {{ detail.warranty }}
                            </template>
                            <template v-else>
                              <em>No Warranty Terms &amp; Conditions</em>
                            </template>
                          </p>
                          <p class="m-0 p-2 w-50 custom-grey-border">
                            <template v-if="detail.cancellation">
                              {{ detail.cancellation }}
                            </template>
                            <template v-else>
                              <em>No Cancellation Terms &amp; Conditions</em>
                            </template>
                          </p>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-container>
                </v-tab-item>
                <v-tab-item value="line-item">
                  <QuotationLineItem
                    is-quotation
                    show-detail
                    :db-line-items="dbLineItems"
                    :db-equipments="dbEquipments"
                    :discount-value="detail.discount_value"
                    :currency="detail.currency"
                    :discount-value-type="detail.discount_value_type"
                    :apply-tax="detail.tax_applied"
                    :tax-value="detail.tax_value"
                    :adjustment-value="detail.adjustment"
                    v-on:load:content="getQuotation()"
                  ></QuotationLineItem>
                </v-tab-item>
                <v-tab-item value="invoice">
                  <InternalInvoice
                    type="quotation"
                    :type-id="quotationId"
                  ></InternalInvoice>
                </v-tab-item>
                <v-tab-item value="visit">
                  <InternalVisit
                    is-admin
                    visit-type="all"
                    internal
                    :quotation-id="detail.id"
                    :customer-id="detail.customer && detail.customer.id"
                    :entity-id="detail.entity_id"
                  ></InternalVisit>
                </v-tab-item>
                <v-tab-item
                  v-if="getPermission('history:view')"
                  :value="'history'"
                >
                  <InternalHistoryDetail
                    type="quotation"
                    :type_id="detail.id"
                  ></InternalHistoryDetail>
                </v-tab-item>
                <v-tab-item
                  v-if="getPermission('history:view')"
                  :value="'pdfHistory'"
                >
                  <PDFHistoryDetail
                    type="quotation"
                    :type_id="detail.id"
                    v-on:success="getQuotation()"
                  ></PDFHistoryDetail>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </v-container>
      </perfect-scrollbar>
      <SendMail
        :mail-dialog="mail_dialog"
        title="Send as Email"
        type="quotation"
        v-on:close="mail_dialog = false"
        :type-id="quotationId"
        v-on:success="getQuotation()"
      ></SendMail>
      <Dialog :common-dialog="confirm_dialog" :dialog-width="dialogWidth">
        <template v-slot:title> Confirmation </template>
        <template v-slot:body>
          <v-form ref="statusForm" v-model.trim="formValid" lazy-validation>
            <v-container class="px-10" fluid>
              <p class="font-weight-600 font-size-19">
                Are you sure, you want to
                <span class="font-weight-700">{{ get_confirm_title() }}</span
                >?
              </p>
              <v-layout
                class="my-4"
                v-if="
                  confirm_type == 'mark_as_approved' ||
                  confirm_type == 'mark_as_rejected' ||
                  confirm_type == 'mark_as_accepted'
                "
              >
                <v-flex>
                  <label class="font-weight-500 font-size-16 required"
                    >Remark</label
                  >
                  <v-textarea
                    v-model.trim="actionData.remark"
                    auto-grow
                    dense
                    filled
                    color="cyan"
                    label="Remark"
                    solo
                    flat
                    :rules="[
                      validateRules.required(actionData.remark, 'Remark'),
                      validateRules.minLength(actionData.remark, 'Remark', 1),
                      validateRules.maxLength(
                        actionData.remark,
                        'Remark',
                        1024
                      ),
                    ]"
                    row-height="25"
                  ></v-textarea>
                </v-flex>
              </v-layout>
              <v-layout class="mt-4" v-if="confirm_type == 'mark_as_accepted'">
                <v-flex md12>
                  <label class="font-weight-500 font-size-16">Attachment</label>
                  <FileTemplateName
                    :disabled="formLoading"
                    v-on:file:updated="updateAttachment($event)"
                    allowUpload
                  ></FileTemplateName>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="confirm_loading"
            v-on:click="confirm_dialog = false"
            class="mx-2 custom-grey-border custom-bold-button"
          >
            No! Close
          </v-btn>
          <v-btn
            v-on:click="confirm_submit()"
            :disabled="!formValid || confirm_loading"
            :loading="confirm_loading"
            class="mx-2 custom-bold-button white--text"
            color="cyan"
          >
            Yes! Continue
          </v-btn>
        </template>
      </Dialog>

      <Dialog :common-dialog="download_dialog" :dialog-width="dialogWidth">
        <template v-slot:title> Download Quotation </template>
        <template v-slot:body>
          <v-container class="px-10" fluid>
            <v-col md="12">
              <v-autocomplete
                dense
                color="cyan"
                filled
                id="prepared-by"
                :items="templateList"
                :disabled="pageLoading"
                :loading="pageLoading"
                v-model.trim="download_template"
                label="Download Template"
                solo
                flat
                item-color="cyan"
                item-text="title"
                item-value="value"
                hide-details
                v-on:change="updatePreparedBy()"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="'No Template Found.'"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col md="12" v-if="download_template == 'HYC_Pn_Inst'">
              <label class="font-weight-700 font-size-16"
                >Installation Clause</label
              >
              <!-- <v-textarea
                  auto-grow
                  dense
                  filled
                  flat
                  v-model="profeinstaservice.profe_insta_service"
                  label="Professional Services and Installation Services"
                  solo
                  color="cyan"
                  hide-details
                  row-height="10"
                  
                ></v-textarea> -->
              <editor
                :disabled="formLoading"
                v-model="profeinstaservice.profe_insta_service"
              />
            </v-col>
            <v-col>
              <label class="font-weight-700 font-size-16">Download Date</label>
              <v-menu
                v-model="DatePicker"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    filled
                    flat
                    label="Download Date"
                    hide-details
                    solo
                    :rules="[
                      validateRules.required(download_date, 'Download Date'),
                    ]"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :value="formattedDownloadDate"
                    color="cyan"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="download_date"
                  color="cyan"
                  :min="todayDate"
                  v-on:input="DatePicker = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col>
              <label class="font-weight-700 font-size-16">Valid Till</label>
              <v-menu
                v-model="ValidDatePicker"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    filled
                    flat
                    label="Valid Till"
                    hide-details
                    solo
                    :rules="[validateRules.required(valid_till, 'Valid Till')]"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :value="formattedValidDate"
                    color="cyan"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="valid_till"
                  color="cyan"
                  :min="todayDate"
                  v-on:input="ValidDatePicker = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-container>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="confirm_loading"
            v-on:click="download_dialog = false"
            class="mx-2 custom-grey-border custom-bold-button"
          >
            Close
          </v-btn>
          <v-btn
            v-on:click="download_submit()"
            :disabled="confirm_loading"
            :loading="confirm_loading"
            class="mx-2 custom-bold-button white--text"
            color="cyan"
          >
            Download
          </v-btn>
        </template>
      </Dialog>
    </template>
  </DetailTemplate>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
//import FileTemplate from "@/view/pages/partials/FileTemplate.vue";
import FileTemplateName from "@/view/pages/partials/FileTemplateName.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
import QuotationLineItem from "@/view/pages/partials/Line-Item-Detail-New";
import InternalInvoice from "@/view/pages/partials/Internal-Invoice";
import SendMail from "@/view/pages/partials/Send-Mail";
import InternalVisit from "@/view/pages/visit/Visit-Listing-Template";
import { GET, QUERY, PATCH, PUT } from "@/core/services/store/request.module";
import InternalHistoryDetail from "@/view/pages/partials/Detail/Internal-History-Detail.vue";
import PDFHistoryDetail from "@/view/pages/partials/Detail/PDF-History-Detail.vue";
import JwtService from "@/core/services/jwt.service";
import Dialog from "@/view/pages/partials/Dialog";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import moment from "moment-timezone";
import AppConfiguration from "@/core/config/app.config";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import { PaginationEventBus } from "@/core/lib/pagination.lib";
export default {
  mixins: [CommonMixin, ValidationMixin],
  data() {
    return {
      formLoading: false,
      tab: null,
      quotationId: 0,
      pageLoaded: false,
      statusLoading: false,
      mail_dialog: false,
      invoiceLoading: false,
      pageLoading: false,
      todayDate: null,
      DatePicker: false,
      ValidDatePicker: false,
      confirm_loading: false,
      download_template: "HYC_Pn_Inst",
      download_dialog: false,
      confirm_dialog: false,
      download_date: moment().format("YYYY-MM-DD"),
      //valid_till: moment().format("YYYY-MM-DD"),
      valid_till: moment().add(14, "days").format("YYYY-MM-DD"),
      confirm_type: null,
      quotationTab: "other",
      moreActions: [],
      detail: {},
      db_approval: {},
      dbLineItems: [],
      dbEquipments: [],
      templateList: [],
      actionData: {
        remark: null,
        attachments: [],
        status: null,
      },
      userDetails: new Object(),
      attachments: [],
      profeinstaservice: {
        profe_insta_service: null,
        quotation: null,
      },
    };
  },
  methods: {
    updateAttachment(param) {
      this.attachments = param;
    },
    get_confirm_title() {
      if (this.confirm_type == "mark_as_closed") {
        return "Mark as Lost";
      } else if (this.confirm_type == "mark_as_cancelled") {
        return "Mark as Cancelled";
      } else if (this.confirm_type == "mark_as_pending") {
        return "Mark as Pending";
      } else if (this.confirm_type == "mark_as_rejected") {
        return "Mark as Rejected";
      } else if (this.confirm_type == "mark_as_accepted") {
        return "Mark as Accepted";
      } else if (this.confirm_type == "mark_as_approved") {
        return "Mark as Approved";
      }
    },
    confirm_submit() {
      const _this = this;
      const validateStatus = _this.$refs.statusForm.validate();
      const formErrors = _this.validateForm(_this.$refs.statusForm);
      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }
      if (!validateStatus) {
        return false;
      }

      switch (this.confirm_type) {
        case "mark_as_closed":
          this.updateStatus("mark_as_closed");
          break;
        case "mark_as_cancelled":
          this.updateStatus("mark_as_cancelled");
          break;
        case "mark_as_pending":
          this.updateStatus("mark_as_pending");
          break;
        case "mark_as_rejected":
          this.updateStatus("mark_as_rejected");
          break;
        case "mark_as_accepted":
          this.updateStatus("mark_as_accepted");
          break;
        case "mark_as_approved":
          this.updateStatus("mark_as_approved");
          break;
      }
    },
    async download_submit() {
      if (this.download_template && this.download_dialog) {
        await this.ProfesnalInstaService();
      }
    },
    downloadPDFNew() {
      const { id } = this.detail;
      const token = JwtService.getToken();
      const url = `${this.$apiURL}quotation/${id}/pdf/download/${this.download_template}?token=${token}&date=${this.download_date}&valid_till=${this.valid_till}`;
      // console.log(url);
      window.open(url, "_blank");
      this.profeinstaservice.profe_insta_service = this.profeinstaservice
        .profe_insta_service
        ? this.profeinstaservice.profe_insta_service
        : this.detail.profe_insta_service;
      this.download_dialog = false;
    },
    getLineItems(data) {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "line-item/new",
          data,
        })
        .then(({ data }) => {
          if (data.length) {
            const result = [];

            for (let i = 0; i < data.length; i++) {
              result.push({
                id: data[i].id,
                group: data[i].group,
                group_primary: data[i].group_primary,
                product: data[i].product,
                product_id: data[i].product_id,
                serial_no: data[i].serial_no,
                eq_model: data[i].eq_model,
                location: data[i].location,
                product_type: data[i].product_type,
                to_equipment: data[i].to_equipment,
                has_warranty: data[i].has_warranty,
                warranty: data[i].warranty,
                description: data[i].description,
                rate: data[i].rate,
                project_price: data[i].project_price,
                is_optional: data[i].is_optional,
                quantity: data[i].quantity,
                uom: data[i].uom,
                total: data[i].total,
                visit_barcode: data[i].visit_barcode,
              });
            }

            _this.$nextTick(() => {
              _this.dbLineItems = _this.lodash.filter(result, function (row) {
                return (
                  row.product_type == "goods" || row.product_type == "service"
                );
              });
              _this.dbEquipments = _this.lodash.filter(result, {
                product_type: "equipment",
              });
            });
          }
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    updateMoreAction(type) {
      switch (type) {
        case "edit":
          this.editQuotation();
          break;
        case "revise":
          this.reviseQuotation();
          break;
        case "convert_visit":
          this.routeToJob();
          break;
        case "signature_link":
          this.getSignatureLink();
          break;
        case "convert_invoice":
          this.routeToInvoice();
          break;
        case "duplicate":
          this.routeToDuplicate();
          break;
        case "mark_as_closed":
          this.confirm_dialog = true;
          this.confirm_type = "mark_as_closed";
          break;
        case "mark_as_cancelled":
          this.confirm_dialog = true;
          this.confirm_type = "mark_as_cancelled";
          break;
        case "send_as_email":
          this.mail_dialog = true;
          break;
        case "mark_as_pending":
          this.confirm_dialog = true;
          this.confirm_type = "mark_as_pending";
          break;
        case "mark_as_rejected":
          this.confirm_dialog = true;
          this.confirm_type = "mark_as_rejected";
          break;
        case "mark_as_accepted":
          this.confirm_dialog = true;
          this.confirm_type = "mark_as_accepted";
          break;
        case "mark_as_approved":
          this.confirm_dialog = true;
          this.confirm_type = "mark_as_approved";
          break;
        case "download_pdf":
          this.download_dialog = true;
          //this.downloadPDF();
          break;
        case "print":
          this.printPDF();
          break;
      }
    },
    getSignatureLink() {
      this.$store
        .dispatch(GET, {
          url: "quotation/" + this.quotationId + "/signature-link",
        })
        .then(({ data }) => {
          const { signature_link } = data;
          window.open(signature_link, "_blank");
        })
        .catch((error) => {
          this.logError(error);
        });
    },
    updateStatus(status) {
      if (this.statusLoading) {
        return false;
      }
      this.actionData.attachments = this.attachments;
      this.actionData.status = status;
      this.statusLoading = true;
      this.$store
        .dispatch(PATCH, {
          url: "quotation/" + this.quotationId + "/status",
          data: this.actionData,
        })
        .then(() => {
          this.getQuotation();
          this.confirm_dialog = false;
          this.confirm_type = null;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.statusLoading = false;
          this.confirm_loading = false;
        });
    },
    ProfesnalInstaService() {
      const _this = this;
      // _this.pageLoading = true;
      if (_this.download_template == "HYC_Pn_Inst") {
        _this.$store
          .dispatch(PUT, {
            url: "professional-service/" + this.quotationId,
            data: {
              profe_insta_service: _this.profeinstaservice.profe_insta_service,
              quotation: _this.quotationId,
            },
          })
          .then(() => {
            this.downloadPDFNew();
            PaginationEventBus.$emit("histories-download-refresh", true);
          });
      } else {
        const { id } = this.detail;
        const token = JwtService.getToken();
        const url = `${this.$apiURL}quotation/${id}/pdf/download/${this.download_template}?token=${token}&date=${this.download_date}&valid_till=${this.valid_till}`;
        // console.log(url);
        window.open(url, "_blank");
        this.profeinstaservice.profe_insta_service =
          this.detail.profe_insta_service;
        this.download_dialog = false;

        PaginationEventBus.$emit("histories-download-refresh", true);
      }
    },

    downloadPDF() {
      const { id } = this.detail;
      const token = JwtService.getToken();
      const url = `${this.$apiURL}quotation/${id}/pdf/download?token=${token}`;
      window.open(url, "_blank");
    },
    printPDF() {
      const { id } = this.detail;
      const token = JwtService.getToken();
      const url = `${this.$apiURL}quotation/${id}/print?token=${token}`;
      window.open(url, "_blank");
    },
    routeToJob() {
      const { billing, billing_person, customer, property, property_person } =
        this.detail;
      this.$router.push(
        this.getDefaultRoute("job.create", {
          query: {
            quotation: this.quotationId,
            customer: customer ? customer.id : null,
            property_contact_person: property_person
              ? property_person.id
              : null,
            billing_contact_person: billing_person ? billing_person.id : null,
            property: property ? property.id : null,
            billing: billing ? billing.id : null,
          },
        })
      );
    },
    routeToInvoice() {
      const { billing, billing_person, customer, property, property_person } =
        this.detail;
      this.$router.push(
        this.getDefaultRoute("invoice.create", {
          query: {
            quotation: this.quotationId,
            customer: customer ? customer.id : null,
            property_contact_person: property_person
              ? property_person.id
              : null,
            billing_contact_person: billing_person ? billing_person.id : null,
            property: property ? property.id : null,
            billing: billing ? billing.id : null,
          },
        })
      );
    },
    routeToDuplicate() {
      const { billing, billing_person, customer, property, property_person } =
        this.detail;
      this.$router.push(
        this.getDefaultRoute("quotation.create", {
          query: {
            customer: customer ? customer.id : null,
            billing: billing ? billing.id : null,
            property: property ? property.id : null,
            property_person: property_person ? property_person.id : null,
            billing_person: billing_person ? billing_person.id : null,
            duplicate: this.quotationId,
          },
        })
      );
    },
    reviseQuotation() {
      const { billing, billing_person, customer, property, property_person } =
        this.detail;
      this.$router.push(
        this.getDefaultRoute("quotation.create", {
          query: {
            customer: customer ? customer.id : null,
            billing: billing ? billing.id : null,
            property: property ? property.id : null,
            property_person: property_person ? property_person.id : null,
            billing_person: billing_person ? billing_person.id : null,
            revise: this.quotationId,
          },
        })
      );
    },
    editQuotation() {
      const { billing, billing_person, customer, property, property_person } =
        this.detail;
      this.$router.push(
        this.getDefaultRoute("quotation.update", {
          params: {
            id: this.quotationId,
          },
          query: {
            customer: customer ? customer.id : null,
            billing: billing ? billing.id : null,
            property: property ? property.id : null,
            property_person: property_person ? property_person.id : null,
            billing_person: billing_person ? billing_person.id : null,
          },
        })
      );
    },
    getQuotation() {
      this.$store
        .dispatch(GET, { url: "quotation/" + this.quotationId })
        .then(({ data }) => {
          this.detail = data;
          this.profeinstaservice.profe_insta_service =
            this.detail.profe_insta_service;

          this.db_approval = data.db_approval;

          this.moreActions = data.more_actions;

          this.getLineItems({
            quotation: this.detail.id,
          });

          this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Quotation", route: "quotation" },
            { title: "Detail" },
            { title: data.barcode },
          ]);
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.pageLoaded = true;
        });
    },
  },
  created() {
    // console.log(this.$route);

    this.quotationId = this.lodash.toSafeInteger(this.$route.params.id);
    this.quotationTab = this.$route.query.tab;

    // console.log(this.quotationTab, "this.quotationTab");

    this.templateList = [
      /* { title: "HYC Pn Rm", value: "HYC_Pn_Rm" },
      { title: "HYC Rm", value: "HYC_Rm" },
      { title: "HYC Rm Inst", value: "HYC_Rm_Inst" }, */
      { title: "HYC Pn Inst", value: "HYC_Pn_Inst" },
      { title: "HYC Pn None", value: "HYC_Pn_None" },
    ];
  },
  mounted() {
    this.getQuotation();
    this.userDetails = JwtService.currentUser();
    //console.log(this.userDetails, "hello");
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 40);
    },
    formattedDownloadDate() {
      let Config = AppConfiguration.get();
      if (Config) {
        return moment(this.download_date).format(Config.dateFormat);
      }
      return this.download_date;
    },
    formattedValidDate() {
      let Config = AppConfiguration.get();
      if (Config) {
        return moment(this.valid_till).format(Config.dateFormat);
      }
      return this.valid_till;
    },
  },
  components: {
    CustomStatus,
    FileTemplateName,
    DetailTemplate,
    InternalVisit,
    SendMail,
    Dialog,
    InternalInvoice,
    QuotationLineItem,
    InternalHistoryDetail,
    PDFHistoryDetail,
    editor: TinyMCE,
  },
};
</script>
