<template>
  <Dialog :commonDialog="dialog">
    <template v-slot:title>
      <v-layout>
        <v-flex
          >Converted Remarks
          <!-- <pre>{{data_histories}}</pre> --></v-flex
        >
      </v-layout>
    </template>
    <template v-slot:body>
      <div>
        <draggable
          tag="ul"
          v-model="defaultColDefs"
          class="draggable-group"
          handle=".draggable-drag-icon"
        >
          <v-container class="py-0">
            <v-row class="py-0">
              <v-col md="12" class="py-0">
                <label class="font-weight-500 font-size-16 required"
                  >Remark</label
                >
                <v-textarea
                  v-model.trim="historiesdata.checked_remark_pdf"
                  auto-grow
                  dense
                  filled
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  color="cyan"
                  label="Remark"
                  solo
                  flat
                  row-height="25"
                  :rules="[
                    validateRules.required(
                      historiesdata.checked_remark_pdf,
                      'Remark'
                    ),
                    validateRules.minLength(
                      historiesdata.checked_remark_pdf,
                      'Remark',
                      1
                    ),
                    validateRules.maxLength(
                      historiesdata.checked_remark_pdf,
                      'Remark',
                      1024
                    ),
                  ]"
                ></v-textarea>
              </v-col>

              <v-col>
                <label class="font-weight-700 font-size-16"
                  >Accepted Date</label
                >
                <v-menu
                  v-model="DatePicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      filled
                      flat
                      label="Accepted Date"
                      hide-details
                      solo
                      :rules="[
                        validateRules.required(accepted_date, 'Accepted Date'),
                      ]"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :value="formattedAcceptedDate"
                      color="cyan"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="accepted_date"
                    color="cyan"
                    :min="todayDate"
                    v-on:input="DatePicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <template v-if="quotationDetails.is_contract">
                <table width="100%">
                  <tr>
                    <td width="150" class="font-weight-600">
                      <label for="duration-value">Duration</label>
                    </td>
                    <td>
                      <v-layout>
                        <v-flex md6>
                          <v-text-field
                            dense
                            filled
                            id="duration-value"
                            color="cyan"
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            v-mask="'##'"
                            v-model.trim="quotationDetails.duration_value"
                            label="Duration"
                            solo
                            flat
                            hide-details
                            v-on:keyup="updateDuration()"
                          ></v-text-field>
                        </v-flex>
                        <v-flex md6>
                          <v-select
                            dense
                            color="cyan"
                            filled
                            id="duration-type"
                            :items="durationTypeList"
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            v-model.trim="quotationDetails.duration_type"
                            v-on:change="updateDuration()"
                            label="Duration"
                            solo
                            flat
                            item-color="cyan"
                            hide-details
                          >
                            <template v-slot:no-data>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title
                                    v-html="'No Duration Type Found.'"
                                  ></v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                          </v-select>
                        </v-flex>
                      </v-layout>
                    </td>
                  </tr>
                  <tr>
                    <td width="150" class="font-weight-600">
                      <label for="start-date-picker">Dates</label>
                    </td>
                    <td>
                      <v-layout>
                        <v-flex md6
                          ><DatePicker
                            solo
                            flat
                            :default-date="quotationDetails.contract_start"
                            v-model="quotationDetails.contract_start"
                          ></DatePicker
                        ></v-flex>
                        <v-flex md6
                          ><DatePicker
                            solo
                            flat
                            :default-date="quotationDetails.contract_end"
                            v-model="quotationDetails.contract_end"
                          ></DatePicker
                        ></v-flex>
                      </v-layout>
                    </td>
                  </tr>

                  <tr>
                    <td width="150" class="font-weight-600">
                      <label for="no-of-services">No. of Services</label>
                    </td>
                    <td>
                      <v-text-field
                        dense
                        filled
                        id="no-of-services"
                        color="cyan"
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        v-mask="'####'"
                        :rules="[
                          validateRules.required(
                            quotationDetails.total_service,
                            'No. of Services'
                          ),
                          validateRules.minLength(
                            quotationDetails.total_service,
                            'No. of Services',
                            1
                          ),
                          validateRules.maxLength(
                            quotationDetails.total_service,
                            'No. of Services',
                            100
                          ),
                        ]"
                        v-model.trim="quotationDetails.total_service"
                        label="No. of Services"
                        solo
                        flat
                        hide-details
                      ></v-text-field>
                    </td>
                  </tr>
                </table>
              </template>

              <v-col md="12" class="py-0">
                <v-layout class="mt-4">
                  <v-flex md12>
                    <label class="font-weight-500 font-size-16"
                      >Attachment</label
                    >
                    <FileTemplateName
                      :disabled="formLoading"
                      v-on:file:updated="updateAttachment($event)"
                      allowUpload
                    ></FileTemplateName>
                  </v-flex>
                </v-layout>
              </v-col>
            </v-row>
          </v-container>
        </draggable>
      </div>
    </template>
    <template v-slot:action>
      <v-btn
        :disabled="pageLoading"
        :loading="pageLoading"
        class="mx-2 custom-grey-border custom-bold-button white--text"
        color="cyan"
        v-on:click="updatehistory"
      >
        Submit
      </v-btn>
      <v-btn
        :disabled="pageLoading"
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="ClosedataRefrens"
      >
        Close
      </v-btn>
    </template>
  </Dialog>
</template>
<script>
import draggable from "vuedraggable";
import Dialog from "@/view/pages/partials/Dialog.vue";
import { PUT } from "@/core/services/store/request.module";
import { PaginationEventBus } from "@/core/lib/pagination.lib";
//import { WeekEventBus } from "@/core/lib/message.lib";
import FileTemplateName from "@/view/pages/partials/FileTemplateName.vue";
//import { map } from "lodash";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import moment from "moment-timezone";
import AppConfiguration from "@/core/config/app.config";
import DatePicker from "@/view/pages/partials/Datepicker.vue";

export default {
  mixins: [ValidationMixin],
  name: "All-visit",
  props: {
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    data_histories: {
      type: Object,
      default: function () {
        return {};
      },
    },
    pageLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // checked_remark_pdf: null,
      historiesdata: {
        checked_remark_pdf: null,
        histories: null,
      },
      durationTypeList: [
        { text: "Year", value: "year" },
        { text: "Month", value: "month" },
      ],
      quotationDetails: {
        is_contract: null,
        duration_value: 1,
        duration_type: "year",
        contract_start: moment().format("YYYY-MM-DD"),
        contract_end: moment().add(1, "year").format("YYYY-MM-DD"),
        duration: null,
        total_service: null,
      },
      histories: null,
      postalcode: null,
      visit_postal: {},
      checkboxclose: true,
      defaultColShow: [],
      defaultColDefs: [],

      attachments: [],
      todayDate: null,
      DatePicker: false,
      accepted_date: moment().format("YYYY-MM-DD"),

      // indeterminate: false,
    };
  },
  watch: {
    data_histories: {
      deep: true,
      //  immediate: true,
      handler(param) {
        this.histories = param.id;
        this.checkedvalue = param.checked;
        this.quotationDetails = param.quotation;
        // console.log(this.histories, "this.historieshh");
      },
    },
  },
  methods: {
    updateDuration() {
      const { contract_start, duration_value, duration_type } =
        this.quotationDetails;
      this.quotationDetails.contract_end = moment(contract_start)
        .add(duration_value, duration_type)
        .format("YYYY-MM-DD");
    },
    updateAttachment(param) {
      this.attachments = param;
    },
    ClosedataRefrens() {
      PaginationEventBus.$emit("histories-on-refresh", true);
      this.$emit("close-dialog", true);
    },

    updatehistory() {
      const _this = this;

      if (!_this.historiesdata.checked_remark_pdf) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Remark is required")
        );
        return false;
      }
      _this.pageLoading = true;
      _this.$store
        .dispatch(PUT, {
          url: "histories/" + this.histories,
          data: {
            checked_remark_pdf: _this.historiesdata.checked_remark_pdf,
            histories: _this.histories,
            checked: _this.checkedvalue,
            attachments: _this.attachments,
            contract_details: _this.quotationDetails,
            accepted_date: _this.accepted_date,
          },
        })
        .then(() => {
          _this.historiesdata.checked_remark_pdf = "";
          _this.attachments = [];
          _this.$emit("close-dialog", true);
          _this.$emit("get-validityoptions", true);
          _this.$emit("success", true);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  computed: {
    formattedAcceptedDate() {
      let Config = AppConfiguration.get();
      if (Config) {
        return moment(this.accepted_date).format(Config.dateFormat);
      }
      return this.accepted_date;
    },
  },
  components: {
    DatePicker,
    draggable,
    Dialog,
    FileTemplateName,
  },
};
</script>
